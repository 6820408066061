exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-big-data-for-business-page-js": () => import("./../../../src/templates/big-data-for-business-page.js" /* webpackChunkName: "component---src-templates-big-data-for-business-page-js" */),
  "component---src-templates-big-data-solutions-page-js": () => import("./../../../src/templates/big-data-solutions-page.js" /* webpackChunkName: "component---src-templates-big-data-solutions-page-js" */),
  "component---src-templates-big-data-training-page-js": () => import("./../../../src/templates/big-data-training-page.js" /* webpackChunkName: "component---src-templates-big-data-training-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-company-page-js": () => import("./../../../src/templates/company-page.js" /* webpackChunkName: "component---src-templates-company-page-js" */),
  "component---src-templates-complex-copy-page-js": () => import("./../../../src/templates/complex-copy-page.js" /* webpackChunkName: "component---src-templates-complex-copy-page-js" */),
  "component---src-templates-consultation-page-js": () => import("./../../../src/templates/consultation-page.js" /* webpackChunkName: "component---src-templates-consultation-page-js" */),
  "component---src-templates-contact-ads-page-js": () => import("./../../../src/templates/contact-ads-page.js" /* webpackChunkName: "component---src-templates-contact-ads-page-js" */),
  "component---src-templates-contact-terms-and-conditions-page-js": () => import("./../../../src/templates/contact-terms-and-conditions-page.js" /* webpackChunkName: "component---src-templates-contact-terms-and-conditions-page-js" */),
  "component---src-templates-cookies-page-js": () => import("./../../../src/templates/cookies-page.js" /* webpackChunkName: "component---src-templates-cookies-page-js" */),
  "component---src-templates-estimate-page-js": () => import("./../../../src/templates/estimate-page.js" /* webpackChunkName: "component---src-templates-estimate-page-js" */),
  "component---src-templates-hej-sverige-big-data-science-cloud-sweden-page-js": () => import("./../../../src/templates/hej-sverige-big-data-science-cloud-sweden-page.js" /* webpackChunkName: "component---src-templates-hej-sverige-big-data-science-cloud-sweden-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-knowledge-base-page-js": () => import("./../../../src/templates/knowledge-base-page.js" /* webpackChunkName: "component---src-templates-knowledge-base-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-solutions-new-page-js": () => import("./../../../src/templates/solutions-new-page.js" /* webpackChunkName: "component---src-templates-solutions-new-page-js" */),
  "component---src-templates-solutions-post-js": () => import("./../../../src/templates/solutions-post.js" /* webpackChunkName: "component---src-templates-solutions-post-js" */),
  "component---src-templates-training-terms-and-conditions-page-js": () => import("./../../../src/templates/training-terms-and-conditions-page.js" /* webpackChunkName: "component---src-templates-training-terms-and-conditions-page-js" */),
  "component---src-templates-workshop-post-js": () => import("./../../../src/templates/workshop-post.js" /* webpackChunkName: "component---src-templates-workshop-post-js" */)
}

